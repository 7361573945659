// colors
$color-alabaster: #fafafa;
$color-cloud-burst: #202a42;
$color-comet: #5d6881;
$color-cyan: #24c1ff;
$color-dodger-blue: #247fff;
$color-ice: #baf7e8;
$color-intertech-blue: #184688;
$color-mercury: #e5e5e5;
$color-pickled-bluewood: #354361;
$color-quill-gray: #d2d1ce;
$color-stardust: #999896;
$color-sunset-orange: #ff4d5b;
$color-white: #fff;
$color-yellow: #ffea00;

// Bootstrap overrides
$blue: $color-dodger-blue;
$red: $color-sunset-orange;
$yellow: $color-yellow;
$teal: $color-ice;
$cyan: $color-cyan;
$body-color: $color-cloud-burst;

// header
$header-height: 3.5rem;

// navbar
$navbar-height: 5.625rem;

// date range bar
$daterange-height: 2.5rem;
$daterange-color: $body-color;
$daterange-background-color: $color-ice;
