@import "../../scss/variables";
$allocation-bar-knob-size: 1.5rem;
$allocation-bar-height: $allocation-bar-knob-size / 3;
$allocation-bar-compact-knob-size: 1rem;
$allocation-bar-compact-height: $allocation-bar-compact-knob-size / 3;

.allocation-bar {
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  .display {
    display: flex;
    align-items: center;
    font-size: $allocation-bar-knob-size;
  }
  .bars {
    position: relative;
    flex: 1 1 0;
    height: $allocation-bar-knob-size;
    margin-top: 0.25rem;
  }
  .bar,
  .knob {
    position: absolute;
    background-color: $body-color;
    &.active {
      background-color: $color-sunset-orange;
    }
  }
  .bar {
    width: 100%;
    height: $allocation-bar-height;
    border-radius: $allocation-bar-height / 3;
    margin: ($allocation-bar-knob-size - $allocation-bar-height) / 2 0;
  }
  .knob-container {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 0.75rem;
    height: 1.5rem;
  }
  .knob {
    height: $allocation-bar-knob-size;
    width: $allocation-bar-knob-size;
    border-radius: $allocation-bar-knob-size / 2;
    margin-left: -$allocation-bar-knob-size / 2;
    font-size: $allocation-bar-knob-size / 2;
    line-height: $allocation-bar-knob-size;
    text-align: center;
    color: $color-white;
  }
  .text {
    margin-left: $allocation-bar-knob-size / 4;
  }
  &.compact {
    .display {
      align-items: flex-end;
      font-size: $allocation-bar-compact-knob-size;
    }
    .bars {
      height: $allocation-bar-compact-knob-size;
      margin: 0;
    }
    .bar {
      width: 100%;
      height: $allocation-bar-compact-height;
      border-radius: $allocation-bar-compact-height / 3;
      margin: 0;
    }
  }
}