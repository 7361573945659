@import "../../scss/variables";

$spinner-delay: -0.42s;
$spinner-margin-top: 6.25rem;
$spinner-size: 1.125rem;
$spinner-size-small: $spinner-size / 2;

.spinner {
  margin: $spinner-margin-top auto 0;
  width: $spinner-size * 3;
  text-align: center;
  line-height: $spinner-size;
}

.spinner > div {
  width: $spinner-size;
  height: $spinner-size;
  background-color: $body-color;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner.spinner-small {
  margin: 0;
  width: $spinner-size-small * 3;
  line-height: $spinner-size-small;

  > div {
    width: $spinner-size-small;
    height: $spinner-size-small;
  }
}

.spinner .bounce1 {
  -webkit-animation-delay: $spinner-delay;
  animation-delay: $spinner-delay;
}

.spinner .bounce2 {
  -webkit-animation-delay: $spinner-delay / 2;
  animation-delay: $spinner-delay / 2;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}