$timesheet-allocation-margin: 1rem;
$timesheet-allocation-compact-margin: $timesheet-allocation-margin / 2;

.timesheet-allocation {
  display: none;
  flex-direction: column;
  justify-content: center;

  .allocation-summary {
    margin: $timesheet-allocation-margin $timesheet-allocation-margin $timesheet-allocation-margin / 2 $timesheet-allocation-margin;
  }

  .allocation-bar {
    margin: $timesheet-allocation-margin / 2 $timesheet-allocation-margin $timesheet-allocation-margin $timesheet-allocation-margin;
  }
}

@media screen and (min-height: 600px) {
  .timesheet-allocation {
    display: flex;
  }
}

.timesheet-allocation.compact {

  .allocation-summary,
  .allocation-bar {
    font-size: 0.625rem;
  }

  .allocation-summary {
    margin: $timesheet-allocation-compact-margin $timesheet-allocation-compact-margin $timesheet-allocation-compact-margin / 2 $timesheet-allocation-compact-margin;
  }

  .allocation-bar {
    margin: $timesheet-allocation-compact-margin / 2 $timesheet-allocation-compact-margin $timesheet-allocation-compact-margin $timesheet-allocation-compact-margin;
  }
}