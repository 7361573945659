@import "../../scss/variables";
@import "../../scss/mixins";

$timesheet-header-height: $header-height;

.timesheet {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.timesheet-header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: $timesheet-header-height;
  .btn-group {
    display: block
  }
  .btn {
    min-width: 4.5rem;
    text-transform: uppercase;
  }
  .btn.today {
    position: absolute;
    left: 0;
    text-transform: uppercase;
  }
}

.timesheet-body-container {
  flex: 1 1 0;
  display: flex;
}

.timesheet-body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .timesheet-row-total {
    display: none;
    flex: 0 0 5rem;
    border: 0;

    .timesheet-allocation {
      flex: 1 1 auto;
    }

    .timesheet-cell-hours {
      color: $body-color;
      background-color: $color-yellow;
    }

    &.submitted {
      .timesheet-cell-hours {
        flex: 1 1 auto;
      }
    }
  }

  @media screen and (min-height: 600px) {
    .timesheet-row-total {
      display: flex;
    }
  }

  .btn {
    color: $color-white;
    background-color: $color-dodger-blue;
  }

  .quick-entry {
    margin-bottom: 0.75rem;
  }
}

.timesheet-new-entry {
  flex: 1 1 auto;
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.timesheet-body-rows {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.timesheet-body-week {
  .timesheet-row-total {
    .timesheet-cell-hours {
      flex: 0 0 24%;
    }
  }
  // .allocation-bar,
  // .allocation-summary {
  //   font-size: 0.625rem;
  //   margin: 0.5rem;
  // }
}

.no-entries {
  padding: 1rem;
}