@import "../../scss/variables";
@import "../../scss/mixins";

.timesheet-cell {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  overflow: hidden;
}

.timesheet-cell-time-entry {
  position: relative;
  background-color: $color-mercury;
  padding: 1rem;

  &.timesheet-cell-detail {
    justify-content: flex-start;
  }

  .lock {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    fill: darken($color-mercury, 20%);
  }

  .timesheet-cell-client {
    font-size: 0.875rem;
    font-weight: 700;
  }

  .timesheet-cell-project {
    margin-bottom: 1rem;
  }

  .timesheet-cell-note {
    text-align: initial;
  }
}

.timesheet-cell-hours,
.timesheet-cell-date {
  color: $color-white;
  background-color: $color-comet;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: 0.625rem;
}

.timesheet-cell-hours-value {
  font-size: 1rem;
  font-weight: 700;
}

.timesheet-cell-new-entry {
    flex: 1 1 auto;
    background-color: $blue;
    color:$white;

  &>div {
    font-size: 2rem;
    line-height: 1.5rem;
  }
}

@include media-breakpoint-up(md) {
  .timesheet-cell {
    font-size: 1rem;
  }

  .timesheet-cell-hours,
  .timesheet-cell-date {
    font-size: 0.875rem;
  }

  .timesheet-cell-hours-value {
    font-size: 1.25rem;
  }
}