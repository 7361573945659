@import "../../scss/variables";

.timesheet-row {
  display: flex;
  flex: 1 1 auto;
  border-bottom: 0.125rem solid $color-quill-gray;

  &:last-child {
    border: 0;
  }

  .timesheet-row-time-entries {
    flex: 1 1 auto;
    display: flex;
    background-color: $color-quill-gray;

    .timesheet-cell {
      margin-right: 0.125rem;

      &:last-child {
        margin: 0;
      }
    }
  }

  .timesheet-cell-hours {
    flex: 0 0 20%;
  }

  &.billable {

    .timesheet-cell-date,
    .timesheet-cell-hours {
      background-color: $color-pickled-bluewood;
    }
  }
}

.timesheet-row.timesheet-row-day.editable {
  cursor: pointer;
}

.timesheet-row.timesheet-row-week {
  cursor: pointer;
  
  .timesheet-cell-date,
  .timesheet-cell-hours {
    flex: 0 0 12%;
  }

  .timesheet-cell-new-entry {
    flex: 0 0 24%;
    button {
      cursor: pointer;
    }
  }
}