@import "../../scss/variables";

.allocation-summary {
  display: flex;
  justify-content: space-between;
  color: $body-color;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  
  table {
    border-spacing: 0;
  }
  table.left td {
    padding-right: 0.25rem;
  }
  table.right {
    text-align: right;
  }
  .due {
    text-transform: none;
  }
  .strong {
    font-weight: 700;
  }
}
