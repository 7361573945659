@import "../../scss/variables";
@import "../../scss/mixins";

.ring-graph {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

@media (min-height: 600px) {
  .ring-graph {
    margin-bottom: 2rem;
    flex: 1 1 auto;
  }
}

.ring-graph-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
  text-align: right;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  span {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin: 0.25rem;
    vertical-align: top;
  }
}

.ring-graph-svg {
  display: flex;
  flex: 1 1 auto;
  svg {
    flex: 1 1 auto;
    padding: 0;
  }
}

@include media-breakpoint-up(md) {
  .ring-graph {
    flex-direction: row;
  }
  .ring-graph-legend {
    margin: 0 2rem;
    order: 1;
  }
  .ring-graph-svg {
    order: 2;
  }
}